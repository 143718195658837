<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i
        ><span class="ml-3">Update Authorization</span>
      </h3>
    </div>

    <div>
      <v-row>
        <div v-if="ClientsList">
          <v-autocomplete
            v-model="clients"
            :items="ClientsList"
            item-text="name"
            item-value="id"
            label="Clients"
            persistent-hint
            :search-input.sync="clientInput"
            @change="fetchData"
          ></v-autocomplete>
        </div>
        <v-col class="d-flex mt-4" cols="12" sm="2">
          <b-form-select
            class="form-control"
            v-model.trim="status"
            :options="statusdrpdown"
            @change="fetchData"
          ></b-form-select>
        </v-col>
        <v-col
          v-if="selectedArray.length > 0"
          class="d-flex mt-4"
          cols="12"
          sm="1"
        >
          <v-btn @click="changeStatus('Active')"> Active </v-btn></v-col
        >
        <v-col
          v-if="selectedArray.length > 0"
          class="d-flex mt-4"
          cols="12"
          sm="1"
        >
          <v-btn @click="changeStatus('Inactive')"> Inactive </v-btn></v-col
        >
        <v-col
          v-if="selectedArray.length > 0"
          class="d-flex mt-4"
          cols="12"
          sm="1"
        >
          <v-btn @click="changeStatus('Terminated')"> Terminated </v-btn></v-col
        >
      </v-row>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        select-mode="single"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
      >
        <!-- A custom formatted header cell for field 'name' -->
        <template #head(clientAuthorizationId)="data">
          <span></span>
          <b-form-checkbox @change="selectedAll"> Select All </b-form-checkbox>
        </template>
        <template #cell(clientAuthorizationId)="row">
          <b-form-checkbox
            @click="selectedId"
            :checked="selectAll"
            @change="selectedId(row.item)"
          >
          </b-form-checkbox>
        </template>
        <template #cell(startDate)="row">
          <span>{{ formatDate(row.item.startDate) }}</span>
        </template>
        <template #cell(endDate)="row">
          <span>{{ formatDate(row.item.endDate) }}</span>
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </select>
          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isfetching: false,

      status: "Active",
      selectAll: false,
      selectedstatus: "Active",
      clientInput: "",
      isSending: false,
      items: [],
      clients: "",
      fields: [
        {
          key: "clientAuthorizationId",
          label: "ID",
        },
        {
          key: "clientName",
          label: "Client Name",
        },
        {
          key: "uci",
          label: "UCI",
        },
        {
          key: "vendorCode",
          label: "Vendor",
        },
        {
          key: "subCode",
          label: "Service",
        },
        {
          key: "authorizationNumber",
          label: "Authorization Number",
        },
        {
          key: "startDate",
          label: "Start",
        },
        {
          key: "endDate",
          label: "End",
        },
        {
          key: "authStatus",
          label: "Status",
        },
      ],
      statusdrpdown: [
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Terminated", text: "Terminated" },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      selectedArray: [],
      id: "",
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalBulkAuthorizeRow: {
      handler: function () {
        this.items = this.bulkAuthorization;
      },
    },
  },
  mounted() {
    this.$store.dispatch("getClientList");
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      "bulkAuthorization",
      "ClientsList",
      "totalBulkAuthorizeRow",
    ]),
  },
  methods: {
    Search() {
      this.fetchData();
    },
    formatDate(obj) { 
      var date = new Date(obj);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    selectedAll(data) {
      this.selectedArray = [];
      if (data) {
        this.items.forEach((x) => {
          this.selectedArray.push(x.clientAuthorizationId);
        });
      }

      if (this.items.length == this.selectedArray.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    async changeStatus(obj) {
      await this.$store.dispatch("updateBulkAuth", {
        authId: this.selectedArray,
        status: obj,
      });
      await this.fetchData();
    },
    selectedId(data) {
      if (
        this.selectedArray.length == 0 ||
        !this.selectedArray.includes(data.clientAuthorizationId)
      ) {
        this.selectedArray.push(data.clientAuthorizationId);
      } else {
        this.selectedArray.splice(data.clientAuthorizationId, 1);
      } 
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getBulkAuth", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          clients: this.clients,
          status: this.status,
        })
        .then((response) => { 
          this.selectedArray = [];
          this.isfetching = false;
          this.items = response.data.output;
          this.totalItems = this.totalBulkAuthorizeRow;
        })
        .catch((ex) => {
          this.isfetching = false;
        });
    },

    onRowSelected(items) {
      this.Edit(items[0]);
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
